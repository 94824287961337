// var $ = require('jquery');
// var $ = require('jquery-match-height');

/* ハンバーガーアイコン */
$(function () {
    var $nav_toggle = $('.icn-humberger , #overlay');
    $nav_toggle.on('click',function(){
        if ($('.icn-humberger').hasClass('is-open')){
            $('.icn-humberger').removeClass('is-open');
            $('html').removeClass('remodal-is-locked');
            $('.nav-sp , #overlay').fadeOut();
        } else {
            $('.icn-humberger').addClass('is-open');
            $('html').addClass('remodal-is-locked');
            $('.nav-sp , #overlay').fadeIn();
        }
    });
});


$(document).ready(function() {
  console.log('hello');
});

$('.p-home__link span').click(function(){
    if ($('#p-home__thought').css('display') == 'none') {
        $('#p-home__thought').slideDown('slow');
        $('.p-home__link').slideUp('slow');
    } else {
        $('#p-home__thought').slideUp('fast');
    }
});

$(function() {
 
    $(".p-home__link span").click(function () {
        var i = $(".p-home__link span").index(this)
        var p = $("#p-home__thought").eq(i).offset().top;
        $('html,body').animate({ scrollTop: p }, 'slow');
        return false;
    });
 
});


$(function(){
	var header = $('.p-langSwitch')
	header_offset = header.offset();
	header_height = header.height();
	 $(window).scroll(function () {
	  if($(window).scrollTop() > header_offset.top + header_height) {
	   header.addClass('fixed');
	  }else {
	   header.removeClass('fixed');
	  }
	});
});


$(function() {
  $('.p-home__effortCarousel-item').matchHeight();
});

$(function(){
  $(".slider--cleared").slider({
  	direction: "up",
  	time: 32,
  	pause: false
  });

  $(".slider--noachieved").slider({
  	direction: "up",
  	time: 32,
  	pause: false
  });

});

